import { ReactElement, useState } from 'react';
import AvatarStack from '../../../atoms/AvatarStack';
import { Link } from '@tanstack/react-router';
import { Button } from '../../../atoms/Button';
import {
  CalendarIcon,
  CheckCircleIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import AssignmentModal from '../../../molecules/Modals/Assignment';
import { Exact, gql, IndexJobContractorsQuery } from '@monorepo/graphql';
import {
  QueryRef,
  useBackgroundQuery,
  useReadQuery,
} from '@apollo/client';
import { useJobContext } from '..';
import EmptyState from '../../../molecules/EmptyState';
import { client } from '../../../../main';
import { format } from 'date-fns';
import { SuspendedComponent } from '../../../atoms/SuspendedComponent';

const INDEX_JOB_CONTRACTORS = gql(`
  query IndexJobContractors ($uuid: String!) {
    indexContractorsForJob (uuid: $uuid) {
      uuid
      isElectrician
      isRoofer 
      isTrainee
      distance
      canBeElectrician
      canBeRoofer
      user {
        uuid
        firstName
        lastName
        avatarSrc
      }
      displayDateRequired
      datesRequired
      contractorProfileUuid
    }
  }
`);

const ContractorPanelInner = ({
  queryRef,
}: {
  queryRef: QueryRef<
    IndexJobContractorsQuery,
    Exact<{
      uuid: string;
    }>
  >;
}): ReactElement => {
  const data = useReadQuery(queryRef);

  return data.data.indexContractorsForJob.length ? (
    <>
      {data.data.indexContractorsForJob.map((c) => (
        <div
          key={c.uuid}
          className="border border-grey-400/40 rounded-md overflow-hidden mb-3"
        >
          <div className="p-2 flex items-center">
            <AvatarStack
              height="h-12"
              width="w-12"
              avatars={[
                {
                  firstName: c.user.firstName,
                  lastName: c.user.lastName,
                  avatarSrc: c.user.avatarSrc,
                },
              ]}
            />
            <div className="space-y-2.5 px-5 flex-grow">
              <Link to="/contacts/$uuid" params={{ uuid: c.user.uuid }}>
                <p className="font-semibold underline">
                  {c.user.firstName} {c.user.lastName}
                </p>
              </Link>
              <span className="text-body-small text-text-low-priority">
                Trade:
                {c.isElectrician && c.isRoofer
                  ? ' Electrician, roofer '
                  : c.isElectrician
                    ? ' Electrician'
                    : ' Roofer'}
              </span>
            </div>
          </div>
          <div className="p-2 bg-background-secondary flex items-center border-b border-grey-400/40">
            <div className="flex items-center flex-grow">
              <MapPinIcon className="size-5 text-grey-400 mr-1" />
              <span className="text-body-small">
                {c.distance.toLocaleString('en-GB', {
                  maximumFractionDigits: 2,
                })}{' '}
                miles
              </span>
            </div>
          </div>
          <div className="p-2 bg-background-secondary flex items-center border-b border-grey-400/40">
            <div className="flex items-center flex-grow">
              <CalendarIcon className="size-5 text-grey-400 mr-1" />
              <span className="text-body-small">{c.displayDateRequired}</span>
            </div>
          </div>
          <div className="p-2 bg-background-secondary flex items-center">
            <CheckCircleIcon className="size-5 text-grey-400 mr-1" />
            <span className="text-body-small">
              {c.isElectrician && c.isRoofer
                ? 'Electrician, roofer '
                : c.isElectrician
                  ? 'Electrician'
                  : 'Roofer'}
            </span>
          </div>
        </div>
      ))}
    </>
  ) : (
    <EmptyState
      title="No contractors"
      description="Start by finding contractors on the left and assigning them"
    />
  );
};

const ContractorPanel = (): ReactElement => {
  const { job } = useJobContext();

  const [queryRef] = useBackgroundQuery(INDEX_JOB_CONTRACTORS, {
    variables: {
      uuid: job.uuid,
    },
  });

  const [showAssignmentModal, setShowAssignmentModal] = useState(false);

  return (
    <>
      <div className="flex-grow">
        <span className="text-body-small text-text-low-priority mb-2 block">
          Contractors
        </span>
      </div>
      <div className="overflow-scroll h-full">
        <SuspendedComponent>
          <ContractorPanelInner queryRef={queryRef} />
        </SuspendedComponent>
      </div>
      <Button
        className="w-full flex items-center justify-center"
        bText="Assign contractors"
        onClick={() => setShowAssignmentModal(true)}
      />
      <AssignmentModal
        queryRef={queryRef}
        open={showAssignmentModal}
        onClose={(success, contractors) => {
          console.log({ success, contractors });
          if (success && contractors) {
            client.graphqlClient().cache.writeQuery({
              query: INDEX_JOB_CONTRACTORS,
              variables: {
                uuid: job.uuid,
              },
              data: {
                indexContractorsForJob: contractors.map((c) => ({
                  ...c,
                  displayDateRequired:
                    c.datesRequired.length === 1
                      ? format(c.datesRequired[0], 'd MMMM yyyy')
                      : `${format(c.datesRequired[0], 'd')} - ${format(
                          c.datesRequired[c.datesRequired.length - 1],
                          'd MMMM yyyy',
                        )}`
                })),
              },
            });
          }
          setShowAssignmentModal(false);
        }}
      />
    </>
  );
};
export default ContractorPanel;
