import { ReactElement } from 'react';
import { Field, Checkbox as HeadlessCheckbox, Label } from '@headlessui/react';
import { ReactNode } from '@tanstack/react-router';

interface Props {
  Icon: ReactNode;
  title: string;
  explainer: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  hasError?: boolean;
  disabled?: boolean;
}

export const SimpleCheckbox = ({
  checked,
  setChecked,
  label,
  disabled,
}: Pick<Props, 'checked' | 'disabled' | 'setChecked'> & { label?: string }) => (
  <Field
    className="flex items-center space-x-2 cursor-pointer"
    disabled={disabled}
  >
    <HeadlessCheckbox
      checked={checked}
      onChange={setChecked}
      as="div"
      className="group"
      disabled={disabled}
    >
      <div className="group flex size-6 items-center border-grey-500 justify-center rounded border-2 bg-white group-data-[checked]:border-primary group-data-[checked]:bg-primary group-hover:[&:not([data-disabled])]:outline-primary group-data-[disabled]:border-grey-700 group-data-[disabled]:bg-grey-900">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          className="opacity-0 transition group-data-[checked]:opacity-100 translate-y-full group-data-[checked]:translate-y-0"
        >
          <path
            d="M13.6485 5.38347L7.31801 11.7135C6.84923 12.1824 6.08879 12.1824 5.61956 11.7135L2.35172 8.44544C1.88276 7.97657 1.88276 7.21604 2.35172 6.74708C2.82077 6.27804 3.58114 6.27804 4.04998 6.74691L6.46904 9.16599L11.95 3.68503C12.419 3.21598 13.1795 3.21633 13.6483 3.68503C14.1172 4.15398 14.1172 4.91424 13.6485 5.38347Z"
            fill="white"
          />
        </svg>
      </div>
    </HeadlessCheckbox>
    {label && <Label>{label}</Label>}
  </Field>
);

const CheckboxCard = ({
  Icon,
  title,
  explainer,
  checked,
  setChecked,
  hasError,
  disabled = false,
}: Props): ReactElement => (
  <HeadlessCheckbox
    checked={checked}
    onChange={setChecked}
    disabled={disabled}
    as="div"
    className={`group p-3 flex flex-col w-40 outline outline-1 [&:not([data-disabled])]:cursor-pointer hover:[&:not([data-disabled])]:bg-primary-600 -outline-offset-1 outline-grey-500 rounded hover:[&:not([data-disabled])]:outline-primary hover:[&:not([data-disabled])]:outline-2 data-[checked]:outline-primary data-[disabled]:text-text-disabled/50 data-[checked]:outline-2 data-[checked]:bg-primary-600
     
      ${hasError ? 'outline-red--100' : ''}`}
  >
    <div className="flex items-start justify-between">
      {Icon}
      <div className="group flex size-6 items-center border-grey-500 justify-center rounded border-2 bg-white group-data-[checked]:border-primary group-data-[checked]:bg-primary group-hover:[&:not([group-data-[disabled]])]:border-primary group-data-[disabled]:border-grey-700 group-data-[disabled]:bg-grey-900">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          className="opacity-0 transition group-data-[checked]:opacity-100 translate-y-full group-data-[checked]:translate-y-0"
        >
          <path
            d="M13.6485 5.38347L7.31801 11.7135C6.84923 12.1824 6.08879 12.1824 5.61956 11.7135L2.35172 8.44544C1.88276 7.97657 1.88276 7.21604 2.35172 6.74708C2.82077 6.27804 3.58114 6.27804 4.04998 6.74691L6.46904 9.16599L11.95 3.68503C12.419 3.21598 13.1795 3.21633 13.6483 3.68503C14.1172 4.15398 14.1172 4.91424 13.6485 5.38347Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
    <p className={`text-start w-full text-body block mb-1 mt-2 font-semibold`}>
      {title}
    </p>
    <span className="text-body-small">{explainer}</span>
  </HeadlessCheckbox>
);
export default CheckboxCard;
